body {
    background-color: #121212; /* Dark Background */
    color: #EFF7F3; /* Light Text */
  }
  
  .hero {
    background-color: #121212; /* Slightly Lighter Background */
    color: #EFF7F3;
    padding: 80px 0;
    text-align: center;
  }
  
  .hero h1 {
    margin-top: 40px;
    font-size: 3.5rem;
    color: #EFF7F3; /* Accent Color */
  }
  
  .hero p {
    font-size: 1.5rem;
    margin-top: 20px;
  }
  
  p {
    color: #808080;
  }
  
  .app-link {
    margin-top: 30px;
  }
  
  .app-link a {
    display: inline-block;
    margin-top: 10px;
  }
  
  .app-link img {
    width: 200px; /* Adjust size as needed */
    transition: transform 0.3s ease;
  }
  
  .app-link img:hover {
    transform: scale(1.05); /* Zoom effect on hover */
  }
  
  .features {
    padding: 60px 0;
    background-color: #1f1f1f;
  }
  
  .feature-icon {
    font-size: 3rem;
    color: #49EFFE; /* Accent Color */
  }
  
  footer {
    background-color: #1f1f1f;
    color: #EFF7F3;
  }
  
  footer p {
    margin: 0;
  }
  
  .icon-container {
    margin-top: 0px;
  }