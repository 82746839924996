.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #121212;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.terms-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.terms-date {
  color: #EFF7F3;
  margin-bottom: 20px;
}

.terms-subtitle {
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
}

.terms-list {
  list-style-type: disc;
  margin-left: 4px;
}

.terms-link {
  color: #49EFFE;
  text-decoration: none;
}

.terms-link:hover {
  text-decoration: underline;
}